@charset "utf-8";

/* neon syntax highlighting (base16) */
$base00: #ffffff;
$base01: #e0e0e0;
$base02: #d0d0d0;
$base03: #b0b0b0;
$base04: #000000;
$base05: #101010;
$base06: #151515;
$base07: #202020;
$base08: #ff0086;
$base09: #fd8900;
$base0a: #aba800;
$base0b: #00c918;
$base0c: #1faaaa;
$base0d: #3777e6;
$base0e: #ad00a1;
$base0f: #cc6633;

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials
